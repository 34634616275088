import { useEffect, useState } from 'react';
import './wall.scss';
import colourSchemes from '../data/colourSchemes.json';
import examples from '../data/examples.json';
import Canvas from './canvas';
import Controls from './controls';
import Collection from './collection';
// import domtoimage from 'dom-to-image-more';
import * as htmlToImage from 'html-to-image';
import Layouts from './layouts';
import Frames from './frames';
import FileSaver from 'file-saver';
import Colours from './colours';
import { pastelScheme, randomColourSchemeFn } from '../utils/colourSchemeGenerator';
import { getRandomInt, isEven } from '../utils/lib';
// import html2canvas from 'html2canvas-pro';

function Wall() {
  const [layout, setLayout] = useState(5);
  const [ratio, setRatio] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [printView, setPrintView] = useState();
  const [texture, setTexture] = useState(true);
  const [frame, setFrame] = useState(1);
  const [colorScheme, setColourScheme] = useState(0);
  const [saved, setSaved] = useState();
  const [history, setHistory] = useState();
  const [showCollection, setShowCollection] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const [showLayouts, setShowLayouts] = useState(false);
  const [showExamples, setShowExamples] = useState(false);
  const [showFrames, setShowFrames] = useState(false);
  const [showColours, setShowColours] = useState(false);
  const [tile, setTile] = useState(false);
  const [pastel, setPastel] = useState(false);
  const [saturation, setSaturation] = useState(1);
  const [blur, setBlur] = useState(0);
  const [canvasState, setCanvasState] = useState([
    {
      background_color: colourSchemes[0][0],
      show: true
    },
    {
      background_color: colourSchemes[0][1],
      show: true
    },
    {
      background_color: colourSchemes[0][2],
      show: true
    },
    {
      background_color: colourSchemes[0][3],
      show: true
    },
    {
      background_color: colourSchemes[0][4],
      show: true
    },
    {
      background_color: colourSchemes[0][5],
      show: true
    },
    {
      background_color: colourSchemes[0][6],
      show: true
    },
    {
      background_color: colourSchemes[0][7],
      show: true
    }
  ]);

  const VARIATIONS = 55;
  const FRAMES = 6;
  // const RATIOS = ['1x1', '4x3', '16x9', '3x4', '9x16'];
  const RATIOS = ['1x1', '4x3', '3x4'];
  useEffect(() => {
    const savedItems = JSON.parse(localStorage.getItem('savedCollection'));
    if (savedItems) {
      setSaved(savedItems);
    }
  }, []);

  useEffect(() => {
    if (saved) {
      localStorage.setItem('savedCollection', JSON.stringify(saved));
    }
  }, [saved]);

  const updateShape = (index, key, value) => {
    const shapeArray = canvasState.map((shape, i) => {
      if (index === i) {
        return {
          ...shape,
          [key]: value
        };
      } else {
        return shape;
      }
    });
    setCanvasState(shapeArray);
    console.log(shapeArray.map((e) => e.background_color));
  };
  const print = () => {
    setPrintView('canvas');
    setTimeout(() => {
      htmlToImage.toBlob(document.getElementById('canvas')).then((blob) => {
        setPrintView('popup');
        const img = new Image();
        const objectURL = URL.createObjectURL(blob);
        img.src = objectURL;
        document.querySelector('.print-view__image').appendChild(img);
        const button = document.createElement('button');
        button.innerText = 'Download';
        button.setAttribute('data-umami-event', 'download');
        button.classList.add('btn', 'btn-secondary');
        document.querySelector('.print-view__buttons').appendChild(button);
        button.addEventListener('click', () => {
          if (window.saveAs) {
            window.saveAs(blob, 'artwork.png');
          } else {
            FileSaver.saveAs(blob, 'artwork.png').catch((error) => console.log(error));
          }
          setPrintView(false);
        });
      });
    }, 100);
  };

  const colourSwap = (randomiseShow, randomSwatch) => {
    const randomColours = pastel ? pastelScheme() : randomColourSchemeFn();
    const newColourSchemeIndex = colourSchemes.length === colorScheme + 1 ? 0 : colorScheme + 1;
    const newColourScheme = randomSwatch ? randomColours : colourSchemes[newColourSchemeIndex];
    const swatch = newColourScheme.map((c, i) => {
      return {
        background_color: c,
        show: randomiseShow && i !== 0 ? isEven(getRandomInt(2)) : canvasState[i].show
      };
    });
    setColourScheme(randomSwatch ? colorScheme : newColourSchemeIndex);
    setCanvasState(swatch);
  };
  const layoutSwap = () => {
    setLayout(layout === VARIATIONS ? 1 : layout + 1);
  };
  const ratioSwap = () => {
    setRatio(ratio + 1 === RATIOS.length ? 0 : ratio + 1);
  };

  const load = (array, index) => {
    setRatio(array[index].ratio);
    setCanvasState(array[index].canvasState);
    setLayout(array[index].layout);
    setTexture(array[index].texture);
    setFrame(array[index].frame);
    setTile(array[index].tile);
    setSaturation(array[index].saturation ? array[index].saturation : 1);
    setBlur(array[index].blur ? array[index].blur : 0);
  }

  const random = () => {
    const rRatio = getRandomInt(RATIOS.length);
    const rFrame = getRandomInt(FRAMES);
    const rLayout = getRandomInt(VARIATIONS);
    setRatio(rRatio);
    setLayout(rLayout + 1);
    setTile(isEven(getRandomInt(2)));
    setFrame(rFrame + 1);
    setTexture(true);
    colourSwap(true, true);
    saveItem(true);
  };

  const saveItem = (addToHistory) => {
    const newSavedItem = {
      ratio: ratio,
      canvasState: canvasState,
      layout: layout,
      texture: texture,
      frame: frame,
      tile: tile,
      saturation: saturation,
      blur: blur
    };
    if (addToHistory) {
      console.log('save')
      if (history) {
        setHistory([...history, newSavedItem]);
      } else {
        setHistory([newSavedItem]);
      }
    } else {
      if (saved) {
        setSaved([...saved, newSavedItem]);
      } else {
        setSaved([newSavedItem]);
      }
    }
  };

  useEffect(() => {
    saveItem(true);
  }, [tile, frame, texture, saturation, ratio, blur, layout]);

 const undo = () => {
    const newHistory = history.splice(-1);
    load(newHistory, newHistory.length-1)
    setHistory(newHistory.length > 0 ? [...newHistory] : [])
 }

  const removeFromCollection = (index) => {
    const newSaved = [...saved];
    newSaved.splice(index, 1);
    setSaved(newSaved.length === 0 ? [] : newSaved);
  };

  const showHideTab = (clicked) => {
    setShowLayouts(clicked === 'layouts' ? !showLayouts : false);
    setShowFrames(clicked === 'frames' ? !showFrames : false);
    setShowExamples(clicked === 'examples' ? !showExamples : false);
    setShowCollection(clicked === 'collection' ? !showCollection : false);
    setShowControls(clicked === 'controls' ? !showControls : false);
    setShowColours(clicked === 'colours' ? !showColours : false);
  };
  return (
    <div
      className={`wall wall--${showControls || showColours ? 'show-controls' : 'hide-controls'} ${
        showCollection || showLayouts || showExamples || showFrames ? 'wall--show-tabs' : ''
      } wall--${printView === 'canvas' ? 'print-view' : ''}`}
    >
      <div className="wall__grid">
        <div className={`wall__controls ${showColours || showControls ? 'wall__controls--active' : ''}`}>
          <div className="wall__controls__toggles">
            <button
              className={`wall__controls__toggle ${showColours ? 'bg-secondary' : 'bg-primary'}`}
              onClick={() => showHideTab('colours')}
            >
              Colours
            </button>
            <button
              className={`wall__controls__toggle ${showControls ? 'bg-secondary' : 'bg-primary'}`}
              onClick={() => showHideTab('controls')}
            >
              Canvas
            </button>
          </div>
          <div className={`wall__controls__content ${showColours || showControls ? 'bg-secondary' : 'bg-primary'}`}>
            {showColours && (
              <Colours
                layout={layout}
                layoutSwap={layoutSwap}
                colourSwap={colourSwap}
                canvasState={canvasState}
                updateShape={updateShape}
                opacity={opacity}
                setOpacity={setOpacity}
              />
            )}
            {showControls && (
              <Controls
                layout={layout}
                layoutSwap={layoutSwap}
                colourSwap={colourSwap}
                ratioSwap={ratioSwap}
                canvasState={canvasState}
                updateShape={updateShape}
                texture={texture}
                setTexture={setTexture}
                frame={frame}
                setFrame={setFrame}
                saved={saved}
                saveItem={saveItem}
                showCollection={showCollection}
                setShowCollection={setShowCollection}
                print={print}
                opacity={opacity}
                setOpacity={setOpacity}
                tile={tile}
                setTile={setTile}
                pastel={pastel}
                setPastel={setPastel}
                saturation={saturation}
                setSaturation={setSaturation}
                blur={blur}
                setBlur={setBlur}
                history={history}
                undo={undo}
              />
            )}
          </div>
        </div>
        <div className="wall__canvas" onClick={() => showHideTab('')}>
          <div className="wall__canvas__background"></div>
          {canvasState && (
            <Canvas
              ratio={RATIOS[ratio]}
              layout={layout}
              frame={frame}
              canvas={canvasState}
              texture={texture}
              printView={printView}
              opacity={opacity}
              tile={tile}
              saturation={saturation}
              blur={blur}
            ></Canvas>
          )}
        </div>
      </div>

      {printView && (
        <div className={`print-view print-view--${printView === 'popup' ? 'popup' : ''}`}>
          <div className="print-view__image"></div>
          <div className="print-view__buttons">
            <button className="btn btn-secondary" onClick={() => setPrintView(false)} data-umami-event="cancel-export">
              Cancel
            </button>
          </div>
        </div>
      )}

      <div
        className={`wall__tabs ${
          showCollection || showExamples || showFrames || showLayouts ? 'wall__tabs--active' : ''
        }`}
      >
        <div className="wall__tabs__toggles">
          <button
            className={`wall__tabs__toggle ${showLayouts ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => showHideTab('layouts')}
            data-umami-event="layouts"
          >
            Layouts
          </button>
          <button
            className={`wall__tabs__toggle ${showCollection ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => showHideTab('collection')}
            data-umami-event="saved"
          >
            Saved
          </button>
          <button
            className={`wall__tabs__toggle ${showExamples ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => showHideTab('examples')}
            data-umami-event="examples"
          >
            Examples
          </button>
          <button
            className={`wall__tabs__toggle ${showFrames ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => showHideTab('frames')}
            data-umami-event="frames"
          >
            Frames
          </button>
        </div>
        <div
          className={`wall__tabs__content ${
            showCollection || showExamples || showFrames || showLayouts ? 'bg-secondary' : 'bg-primary'
          }`}
        >
          {showExamples && <Collection saved={examples} loadSaved={load} RATIOS={RATIOS} />}
          {saved && saved.length > 0 && showCollection && (
            <Collection
              saved={saved}
              loadSaved={load}
              removeFromCollection={removeFromCollection}
              RATIOS={RATIOS}
            />
          )}
          {showLayouts && <Layouts setLayout={setLayout} layouts={VARIATIONS} canvasState={canvasState} />}
          {showFrames && (
            <Frames
              setLayout={setLayout}
              layout={layout}
              canvasState={canvasState}
              setFrame={setFrame}
              frames={FRAMES}
            />
          )}
        </div>
      </div>
      <div className="wall__quick-edit">
        <button className="btn btn-lg btn-accent" onClick={() => random()} data-umami-event="random">
          Random
        </button>
        <button className="btn  btn-lg btn-accent" onClick={() => colourSwap(false, true)} data-umami-event="colour">
          Colour
        </button>
        <button className="btn  btn-lg btn-accent" onClick={() => saveItem()} data-umami-event="save">
          Save
        </button>
      </div>
    </div>
  );
}

export default Wall;
